import {
    madeMirageRegular,
    minion,
    minionIt,
    minionSemiBold,
    theinhardtLight,
    theinhardtLightItalic,
    theinhardtMedium,
    theinhardtRegular,
} from './fonts';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import {
    colors,
    darkThemeOptions as piDarkThemeOptions,
    fontSize,
    lightThemeOptions as piLightThemeOptions,
    PiThemeOptions,
    typography,
} from '@sensei/pi-component';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { SimplePaletteColorOptions } from '@material-ui/core';

const lightThemeOptions = generateThemeOptions(piLightThemeOptions);
const darkThemeOptions = generateThemeOptions(piDarkThemeOptions);
// Type check Ignored because createMuiTheme typing does not support fontDisplay.
// @ts-ignore
export const lightTheme = createMuiTheme(lightThemeOptions);
// Type check Ignored because createMuiTheme typing does not support fontDisplay.
// @ts-ignore
export const darkTheme = createMuiTheme(darkThemeOptions);

export default lightTheme;

function generateThemeOptions(piThemeOptions: PiThemeOptions) {
    return {
        ...piThemeOptions,
        overrides: {
            ...piThemeOptions.overrides,
            MuiCssBaseline: {
                ...piThemeOptions.overrides?.MuiCssBaseline,
                '@global': {
                    ...(piThemeOptions.overrides?.MuiCssBaseline?.[
                        '@global'
                    ] as Record<
                        string,
                        CSSProperties['@font-face'] | CSSProperties
                    >),
                    '@font-face': [
                        minion,
                        minionIt,
                        minionSemiBold,
                        theinhardtLight,
                        theinhardtLightItalic,
                        theinhardtRegular,
                        theinhardtMedium,
                        madeMirageRegular,
                    ],
                    'em, em.MuiTypography-root, .MuiTypography-root em': {
                        fontFamily: [
                            minion.fontFamily,
                            'Times New Roman',
                            'serif',
                        ].join(', '),
                        fontStyle: 'normal',
                    },
                    'i, i.MuiTypography-root, .MuiTypography-root i': {
                        fontStyle: 'italic',
                    },
                    strong: {
                        fontWeight: 500,
                    },
                    b: {
                        letterSpacing: '0.05em',
                        fontWeight: 'inherit',
                    },
                },
            },
            MuiButton: {
                ...piThemeOptions.overrides?.MuiButton,
                root: {
                    ...piThemeOptions.overrides?.MuiButton?.root,
                    '&[direction="backward"]': {
                        paddingLeft: 0,
                        paddingRight: 0,
                        '& .MuiButton-endIcon': {
                            margin: 0,
                        },
                    },
                },
                containedSizeLarge: {
                    borderRadius: `calc(${typography.pxToRem(fontSize)} + 8px)`,
                },
                outlinedSizeLarge: {
                    borderRadius: `calc(${typography.pxToRem(fontSize)} + 8px)`,
                },
            },
            MuiFab: {
                root: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    border: `1px solid ${
                        (piThemeOptions.palette
                            ?.primary as SimplePaletteColorOptions).main ||
                        colors.CARBON
                    }`,
                },
                primary: {
                    backgroundColor: 'transparent',
                    border: `1px solid ${
                        (piThemeOptions.palette
                            ?.secondary as SimplePaletteColorOptions).main ||
                        colors.CARBON
                    }`,
                },
            },
            MuiPickersDay: {
                current: {
                    color: (piThemeOptions.palette
                        ?.primary as SimplePaletteColorOptions).contrastText,
                },
            },
            MuiPickersSlideTransition: {
                transitionContainer: {
                    minHeight: 30,
                },
            },
        },
    };
}
