import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '@sensei/pi-component';

const useLoadingStyles = makeStyles((theme: Theme) =>
    createStyles({
        grid: {
            backgroundColor: colors.SAND,
        },
    }),
);

export default useLoadingStyles;
