import React from 'react';
import { Grid } from '@material-ui/core';
import useLoadingStyles from '../../styles/loading/loading.style';
import ThemeProvider from '../../context/ThemeProvider';
import { PiLoading } from '@sensei/pi-component';

type Message = {
    message?: string;
};

type LoadingPageProps = {
    props: Message;
};
export default function LoadingPage({ props }: LoadingPageProps) {
    const classes = useLoadingStyles();

    return (
        <ThemeProvider>
            <Grid
                className={classes.grid}
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '100vh' }}
            >
                <PiLoading message={props.message}></PiLoading>
            </Grid>
        </ThemeProvider>
    );
}
