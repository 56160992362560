import * as React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';

import theme from '../component/App/theme';

function ThemeProvider({ children }: React.PropsWithChildren<{}>) {
    return (
        <MuiThemeProvider theme={theme}>
            <>{children}</>
        </MuiThemeProvider>
    );
}

export default ThemeProvider;
